import { PaginationParams } from '../../../../interfaces/idefend/pagination-param.interface';
import { PolicyGroupPayload } from '../../../../interfaces/idefend/policy-groups-payload.interface';
import { QuestionManagementReplyPayload } from '../../../../interfaces/idefend/question-reply-payload.interface';
import { StepsValidators } from '../../../../interfaces/idefend/steps-validators.interface';
import { VehicleMake } from '../../../../interfaces/idefend/vehicle-make.interface';
import { FirstStepPayload } from '../../shared/interfaces/first-step-payload.interface';
import { FourthStepPayload } from '../../shared/interfaces/fourth-step-payload.interface';
import { WarrantySecondStepPayload } from '../../shared/interfaces/second-step-payload.interface';
import { ThirdStepPayload } from '../../shared/interfaces/third-step-payload.interface';

export class SetNodes {
  static readonly type = '[UI] Set Warranty Nodes';

  constructor() {}
}
export class SetWarrantyFlag {
  static readonly type = '[UI] Set Warranty Flag';

  constructor(public value: boolean) {}
}
export class GetPortfolios {
  static readonly type = '[iDefend API] Get Warranty portfolios';

  constructor() {}
}

export class GetGroups {
  static readonly type = '[iDefend API] Get Warranty groups';

  constructor() {}
}

export class GetCurrency {
  static readonly type = '[iDefend API] Get Warranty currency';

  constructor(public id: number) {}
}

export class GetLocalizationSettings {
  static readonly type = '[iDefend API] Get Warranty localization settings';

  constructor(public id: number) {}
}

export class GetSodanId {
  static readonly type = '[iDefend API] Get Warranty Sodan Id';

  constructor() {}
}

export class SetSodanReplyHeads {
  static readonly type = '[iDefend API] Set Warranty Sodan Reply Heads';

  constructor(public sodanId: string) {}
}

export class GetProductOffersBySodanId {
  static readonly type =
    '[iDefend API] Get Warranty Product Offers By Sodan Id';

  constructor(public sodanId: string) {}
}

export class SetPolicyGroups {
  static readonly type = '[iDefend API] Set Warranty Policy Groups';

  constructor(public payload: PolicyGroupPayload) {}
}

export class PatchQuestionReply {
  static readonly type = '[iDefend API] Patch Warranty Question Reply';

  constructor(
    public payload: QuestionManagementReplyPayload,
    public query: { locale: string },
    public id: number,
  ) {}
}

export class GetVehicleCategories {
  static readonly type = '[iDefend API] Get Warranty Vehicle Categories';

  constructor(public query: PaginationParams) {}
}

export class GetVehicleUsages {
  static readonly type = '[iDefend API] Get Warranty Vehicle Usages';

  constructor(public query: PaginationParams) {}
}

export class GetVehicleMakes {
  static readonly type = '[iDefend API] Get Warranty Vehicle Makes';

  constructor() {}
}

export class GetVehicleModels {
  static readonly type = '[iDefend API] Get Warranty Vehicle Models';

  constructor() {}
}

export class FilterVehicleModelsByCategory {
  static readonly type = '[UI] Filter Warranty Vehicle Models By Category';

  constructor(
    public categoryId: number,
    public categoryCode: string,
  ) {}
}

export class FilterVehicleModelsByMake {
  static readonly type = '[UI] Filter Warranty Vehicle Models By Make';

  constructor(public selectedVehicleMake: VehicleMake) {}
}

export class PatchFirstStepFormValues {
  static readonly type = '[UI] Patch Warranty First Step Form Values';

  constructor(
    public firstStepPayload: FirstStepPayload,
    public force?: boolean,
  ) {}
}

export class PatchSecondStepFormValues {
  static readonly type = '[UI] Patch Warranty Second Step Form Values';

  constructor(
    public secondStepPayload: WarrantySecondStepPayload,
    public force?: boolean,
  ) {}
}

export class PatchThirdStepFormValues {
  static readonly type = '[UI] Patch Warranty Third Step Form Values';

  constructor(public thirdStepPayload: ThirdStepPayload) {}
}

export class PatchFourthStepFormValues {
  static readonly type = '[UI] Patch Warranty Fourth Step Form Values';

  constructor(public fourthStepPayload: FourthStepPayload) {}
}

export class PatchFifthStepFormValues {
  static readonly type = '[UI] Patch Warranty Fifth Step Form Values';

  constructor(
    public paymentForm: {
      label: string;
      value: string;
    },
  ) {}
}

export class SetActiveStep {
  static readonly type = '[UI] Set Warranty Active Step';

  constructor(public step: number) {}
}

export class ResetPolicyCreatorState {
  static readonly type = '[UI] Reset Warranty Policy Creator State';

  constructor() {}
}

export class SetStepsValidators {
  static readonly type = '[UI] Set Warranty validators';

  constructor(
    public stepValidator: Partial<StepsValidators>,
    public forceClean = false,
  ) {}
}

export class EngineTypeOptions {
  static readonly type = '[UI] Warranty Engine Type Options';

  constructor(public vehicleTypeCode?: string) {}
}
