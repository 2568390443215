import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import * as moment from 'moment/moment';
import { combineLatest, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DropdownInputInterface } from '../../../../interfaces/dropdown-input.interface';
import { Currency } from '../../../../interfaces/idefend/currency.interface';
import { QuestionReplyHead } from '../../../../interfaces/idefend/interfaces';
import { LocalizationSetting } from '../../../../interfaces/idefend/localization-settings.interface';
import { PolicyGroupResponse } from '../../../../interfaces/idefend/policy-groups-response.interface';
import { PortfolioGroups } from '../../../../interfaces/idefend/portfolio-group.interface';
import { Portfolio } from '../../../../interfaces/idefend/portfolio.interface';
import { SodanReplayHeads } from '../../../../interfaces/idefend/sodan-replay-heads.interface';
import { StepsValidators } from '../../../../interfaces/idefend/steps-validators.interface';
import { VehicleMake } from '../../../../interfaces/idefend/vehicle-make.interface';
import { VehicleModel } from '../../../../interfaces/idefend/vehicle-model.interface';
import { VehicleUsage } from '../../../../interfaces/idefend/vehicle-usage.interface';
import { VehiclesCategory } from '../../../../interfaces/idefend/vehilces-category.interface';
import { OfferState } from '../../policy-creator/store/offer.state';
import {
  CalculateOfferPayload,
  VehicleSnapshot,
} from '../../shared/interfaces/calculate-offer-payload.interface';
import { FirstStepPayload } from '../../shared/interfaces/first-step-payload.interface';
import { FourthStepPayload } from '../../shared/interfaces/fourth-step-payload.interface';
import { WarrantySecondStepPayload } from '../../shared/interfaces/second-step-payload.interface';
import { SummaryData } from '../../shared/interfaces/summary-data.interface';
import { ThirdStepPayload } from '../../shared/interfaces/third-step-payload.interface';
import { checkForNullValues } from '../helpers/check-for-null-values';
import { deepEqual } from '../helpers/deep-equal';
import {
  getPurchasePrice,
  getPurchasePriceNet,
  getUsageCode,
  getUsageTypeCode,
  getVatReclaimable,
  offerPayloadMapper,
  purchasePriceInputType,
} from '../helpers/offer-payload-mapper';
import { mapSummaryData } from '../helpers/summary-data-mapper';
import { WarrantyCreatorService } from '../warranty-creator.service';
import {
  EngineTypeOptions,
  FilterVehicleModelsByCategory,
  FilterVehicleModelsByMake,
  GetCurrency,
  GetGroups,
  GetLocalizationSettings,
  GetPortfolios,
  GetProductOffersBySodanId,
  GetSodanId,
  SetSodanReplyHeads,
  GetVehicleCategories,
  GetVehicleMakes,
  GetVehicleModels,
  GetVehicleUsages,
  PatchFifthStepFormValues,
  PatchFirstStepFormValues,
  PatchFourthStepFormValues,
  PatchQuestionReply,
  PatchSecondStepFormValues,
  PatchThirdStepFormValues,
  ResetPolicyCreatorState,
  SetActiveStep,
  SetNodes,
  SetPolicyGroups,
  SetStepsValidators,
  SetWarrantyFlag,
} from './warranty-creator.actions';
import { defaults, WarrantyCreatorStateModel } from './warranty-creator.model';
import { WarrantyOfferState } from './warranty-offer.state';

@State<WarrantyCreatorStateModel>({
  name: 'warrantyCreatorState',
  defaults,
})
@Injectable()
export class WarrantyCreatorState {
  constructor(
    private store: Store,
    private warrantyCreatorService: WarrantyCreatorService,
  ) {}

  @Selector()
  static getNodes({ nodes }: WarrantyCreatorStateModel): any[] {
    return nodes;
  }

  @Selector()
  static getSellerNodeCode({
    sellerNodeCode,
  }: WarrantyCreatorStateModel): string {
    return sellerNodeCode;
  }

  @Selector()
  static getVehicleSnapshot(state: WarrantyCreatorStateModel): VehicleSnapshot {
    const { firstStepPayload, vehicleCategories } = state;
    return {
      engineSize:
        firstStepPayload.engineType.code === 'ELECTRIC'
          ? 0
          : firstStepPayload.engineSize,
      purchasedOn: moment(firstStepPayload.vehicleBuyDate).format('YYYY-MM-DD'),
      makeCode: firstStepPayload.selectedVehicleMake.code,
      modelCode: firstStepPayload.selectedVehicleModel.code,
      categoryCode: vehicleCategories.find(
        (category) =>
          category.id === firstStepPayload.selectedVehicleModel.categoryId,
      ).code,
      mileage: firstStepPayload.mileage,
      firstRegisteredOn: moment(firstStepPayload.vehicleRegDate).format(
        'YYYY-MM-DD',
      ),
      mfgWarrantyInceptedOn: firstStepPayload.manufacturerWarranty
        ? moment(firstStepPayload.vehicleRegDate).format('YYYY-MM-DD')
        : moment('1970-01-01').format('YYYY-MM-DD'),
      mfgWarrantyTerm: firstStepPayload.manufacturerWarranty
        ? firstStepPayload.mfrWarrantyPeriod?.id
        : 0,
      mfgWarrantyMileage: firstStepPayload.manufacturerWarranty
        ? Number(firstStepPayload.mfrWarrantyMileage.code) || 999999
        : 0,
      usageTypeCode: getUsageTypeCode(firstStepPayload),
      purchasePrice: getPurchasePrice(firstStepPayload),
      purchasePriceNet: getPurchasePriceNet(firstStepPayload),
      purchasePriceInputType: purchasePriceInputType(firstStepPayload),
      purchasePriceVatReclaimableCode: getVatReclaimable(firstStepPayload),
      driveTypeCode: firstStepPayload.driveType === '2x4' ? '2WD' : '4WD',
      engineTypeCode: firstStepPayload.engineType['code'],
      usageCode: getUsageCode(firstStepPayload),
    };
  }

  @Selector()
  static isWarranty({ isWarranty }: WarrantyCreatorStateModel): boolean {
    return isWarranty;
  }

  @Selector()
  static state(state: WarrantyCreatorStateModel): WarrantyCreatorStateModel {
    return state;
  }

  @Selector()
  static portfolios({ portfolios }: WarrantyCreatorStateModel): Portfolio[] {
    return portfolios;
  }

  @Selector()
  static groups({ groups }: WarrantyCreatorStateModel): PortfolioGroups[] {
    return groups;
  }

  @Selector()
  static currency({ currency }: WarrantyCreatorStateModel): Currency {
    return currency;
  }

  @Selector()
  static localizationSettings({
    localizationSettings,
  }: WarrantyCreatorStateModel): LocalizationSetting {
    return localizationSettings;
  }

  @Selector()
  static sodanReplyHeads({
    sodanReplyHeads,
  }: WarrantyCreatorStateModel): SodanReplayHeads {
    return sodanReplyHeads;
  }

  @Selector()
  static policyGroups({
    policyGroups,
  }: WarrantyCreatorStateModel): PolicyGroupResponse {
    return policyGroups;
  }

  @Selector()
  static vehicleCategories({
    vehicleCategories,
  }: WarrantyCreatorStateModel): VehiclesCategory[] {
    return vehicleCategories;
  }

  @Selector()
  static vehicleUsages({
    vehicleUsages,
  }: WarrantyCreatorStateModel): VehicleUsage[] {
    return vehicleUsages;
  }

  @Selector()
  static vehicleType({
    firstStepPayload,
  }: WarrantyCreatorStateModel): DropdownInputInterface {
    return firstStepPayload.vehicleType;
  }

  @Selector()
  static vehicleRegDate({
    firstStepPayload,
  }: WarrantyCreatorStateModel): string {
    return firstStepPayload.vehicleRegDate;
  }

  @Selector()
  static vehicleMakes({
    vehicleMakes,
  }: WarrantyCreatorStateModel): VehicleMake[] {
    return vehicleMakes;
  }

  @Selector()
  static vehicleModels({
    vehicleModels,
  }: WarrantyCreatorStateModel): VehicleModel[] {
    return vehicleModels;
  }

  @Selector()
  static vehicleModelsFilter({
    vehicleModelsFilter,
  }: WarrantyCreatorStateModel): VehicleModel[] {
    return vehicleModelsFilter;
  }

  @Selector()
  static haveAcPolicy({
    firstStepPayload,
  }: WarrantyCreatorStateModel): boolean {
    return firstStepPayload.haveACPolicy;
  }

  @Selector()
  static boughtSince120Days({
    firstStepPayload,
  }: WarrantyCreatorStateModel): boolean {
    return firstStepPayload.boughtSince120Days;
  }

  @Selector()
  static vehicleBuyDate({
    firstStepPayload,
  }: WarrantyCreatorStateModel): string {
    return firstStepPayload.vehicleBuyDate;
  }

  @Selector()
  static selectedInsuranceVariant({
    firstStepPayload,
  }: WarrantyCreatorStateModel): string {
    return firstStepPayload.selectedInsuranceVariant;
  }

  @Selector()
  static firstStepPayload({
    firstStepPayload,
  }: WarrantyCreatorStateModel): FirstStepPayload {
    return firstStepPayload;
  }

  @Selector()
  static secondStepPayload({
    secondStepPayload,
  }: WarrantyCreatorStateModel): WarrantySecondStepPayload {
    return secondStepPayload;
  }

  @Selector()
  static thirdStepPayload({
    thirdStepPayload,
  }: WarrantyCreatorStateModel): ThirdStepPayload {
    return thirdStepPayload;
  }

  @Selector()
  static fourthStepPayload({
    fourthStepPayload,
  }: WarrantyCreatorStateModel): FourthStepPayload {
    return fourthStepPayload;
  }

  @Selector()
  static fifthStepPayload({ secondStepPayload }: WarrantyCreatorStateModel): {
    label: string;
    value: string;
  } {
    return secondStepPayload.paymentForm;
  }

  @Selector()
  static getSummaryData({
    firstStepPayload,
    secondStepPayload,
    thirdStepPayload,
    fourthStepPayload,
  }: WarrantyCreatorStateModel): SummaryData {
    return mapSummaryData(
      firstStepPayload,
      secondStepPayload,
      thirdStepPayload,
      fourthStepPayload,
    );
  }

  @Selector()
  static fetchCalculateOfferPayload({
    portfolios,
    vehicleCategories,
    firstStepPayload,
    secondStepPayload,
    nodes,
  }: WarrantyCreatorStateModel): CalculateOfferPayload {
    return offerPayloadMapper(
      portfolios,
      vehicleCategories,
      firstStepPayload,
      secondStepPayload,
      nodes,
    );
  }

  @Selector()
  static getActiveStep(state: WarrantyCreatorStateModel): number {
    return state.activeStep;
  }

  @Selector()
  static getStepsValidators(state: WarrantyCreatorStateModel): StepsValidators {
    return state.stepsValidator;
  }

  @Selector()
  static engineTypeOptions(state: WarrantyCreatorStateModel): unknown {
    return state.engineTypeOptions;
  }

  @Selector()
  static getProductOffersBySodanId(
    state: WarrantyCreatorStateModel,
  ): QuestionReplyHead {
    return state.productOffers;
  }

  @Selector()
  static isSodanLoading(state: WarrantyCreatorStateModel): boolean {
    return state.isSodanLoading;
  }

  @Action(SetWarrantyFlag)
  public setWarrantyFlag(
    { patchState }: StateContext<WarrantyCreatorStateModel>,
    value: boolean,
  ) {
    patchState({
      isWarranty: value,
    });
  }

  @Action(SetNodes)
  public setNodes(ctx: StateContext<WarrantyCreatorStateModel>) {
    return this.warrantyCreatorService.getNodes().pipe(
      tap((nodes) => {
        const state = ctx.getState();
        ctx.setState({ ...state, nodes });
        const sellerNodeCode = nodes.find(
          (node) => node.level.code === 'N6',
        )?.code;
        ctx.patchState({ sellerNodeCode: sellerNodeCode });
        return nodes;
      }),
    );
  }

  @Action(GetPortfolios)
  public getPortfolios(ctx: StateContext<WarrantyCreatorStateModel>) {
    return this.warrantyCreatorService.getPortfolios().pipe(
      tap((portfolios) => {
        const state = ctx.getState();
        ctx.setState({ ...state, portfolios });
        return portfolios;
      }),
    );
  }

  @Action(GetGroups)
  public getGroups({ patchState }: StateContext<WarrantyCreatorStateModel>) {
    return this.warrantyCreatorService.getGroups().pipe(
      tap((groups) => {
        patchState({ groups: groups });
      }),
    );
  }

  @Action(GetCurrency)
  public getCurrency(
    { patchState }: StateContext<WarrantyCreatorStateModel>,
    { id }: GetCurrency,
  ) {
    return this.warrantyCreatorService.getCurrency(id).pipe(
      tap((currency) => {
        patchState({ currency });
      }),
    );
  }

  @Action(GetLocalizationSettings)
  public getLocalizationSettings(
    { patchState }: StateContext<WarrantyCreatorStateModel>,
    { id }: GetLocalizationSettings,
  ) {
    return this.warrantyCreatorService.getLocalizationSettings(id).pipe(
      tap((localizationSettings) => {
        patchState({ localizationSettings });
      }),
    );
  }

  @Action(GetSodanId)
  public getSodanId(ctx: StateContext<WarrantyCreatorStateModel>): number {
    return ctx.getState().sodanReplyHeads.id;
  }

  @Action(SetSodanReplyHeads)
  public GetSodanReplyHeads(
    ctx: StateContext<WarrantyCreatorStateModel>,
    { sodanId }: SetSodanReplyHeads,
  ): Observable<SodanReplayHeads> {
    ctx.patchState({ isSodanLoading: true });
    return this.warrantyCreatorService.getSodanReplyHeads(sodanId).pipe(
      tap((sodanReplyHeads) => {
        const state = ctx.getState();
        ctx.setState({ ...state, sodanReplyHeads });
      }),
    );
  }

  @Action(GetProductOffersBySodanId)
  public getProductOffersBySodanId(
    ctx: StateContext<WarrantyCreatorStateModel>,
    { sodanId }: GetProductOffersBySodanId,
  ) {
    return this.warrantyCreatorService.getProductOffersBySodanId(sodanId).pipe(
      tap((productOffers) => {
        ctx.patchState({
          productOffers: productOffers,
          isSodanLoading: false,
        });
      }),
    );
  }

  @Action(SetPolicyGroups)
  public SetPolicyGroups(
    { patchState }: StateContext<WarrantyCreatorStateModel>,
    { payload }: SetPolicyGroups,
  ) {
    return this.warrantyCreatorService.setPolicyGroups(payload).pipe(
      tap((policyGroups) => {
        patchState({ policyGroups });
      }),
    );
  }

  @Action(PatchQuestionReply)
  public PatchQuestionReply(
    { getState, patchState }: StateContext<WarrantyCreatorStateModel>,
    { payload, query, id }: PatchQuestionReply,
  ) {
    return this.warrantyCreatorService
      .patchQuestionReply(payload, query, id)
      .pipe(
        tap((replyResponse) => {
          const sodanReplyHeadsDto = getState().sodanReplyHeads;
          sodanReplyHeadsDto.replies = sodanReplyHeadsDto.replies.map(
            (reply) => {
              if (reply.id === replyResponse.id) {
                return replyResponse;
              }
              return reply;
            },
          );
          patchState({
            sodanReplyHeads: sodanReplyHeadsDto,
          });
        }),
      );
  }

  @Action(GetVehicleCategories, { cancelUncompleted: true })
  public GetVehicleCategories(
    { patchState }: StateContext<WarrantyCreatorStateModel>,
    { query }: GetVehicleCategories,
  ) {
    return this.warrantyCreatorService.getVehicleCategories(query).pipe(
      tap((vehicleCategories) => {
        patchState({ vehicleCategories });
      }),
    );
  }

  @Action(GetVehicleUsages)
  public GetVehicleUsages(
    { patchState }: StateContext<WarrantyCreatorStateModel>,
    { query }: GetVehicleUsages,
  ) {
    return this.warrantyCreatorService.getVehicleUsages(query).pipe(
      tap((vehicleUsages) => {
        patchState({ vehicleUsages });
      }),
    );
  }

  @Action(GetVehicleMakes)
  public getVehicleMakes({
    patchState,
  }: StateContext<WarrantyCreatorStateModel>) {
    return this.warrantyCreatorService.getVehicleMakes().pipe(
      tap((vehicleMakes) => {
        patchState({ vehicleMakes });
      }),
    );
  }

  @Action(GetVehicleModels)
  public getVehicleModels({
    patchState,
  }: StateContext<WarrantyCreatorStateModel>) {
    return this.warrantyCreatorService.getVehicleModels().pipe(
      tap((vehicleModels) => {
        patchState({ vehicleModels });
      }),
    );
  }

  @Action(FilterVehicleModelsByCategory)
  public filterVehicleModelsByCategory(
    { getState, patchState }: StateContext<WarrantyCreatorStateModel>,
    { categoryId, categoryCode }: FilterVehicleModelsByCategory,
  ) {
    const vehicleMakesReq =
      this.warrantyCreatorService.getVehicleMakes(categoryCode);
    const vehicleModelsReq = this.warrantyCreatorService.getVehicleModels();

    return combineLatest([vehicleMakesReq, vehicleModelsReq]).pipe(
      tap(([vehicleMakes, vehicleModels]) => {
        const vehicleModelsFilter = vehicleModels.filter(
          (model) => model.categoryId === categoryId,
        );
        patchState({
          vehicleMakes: vehicleMakes,
          vehicleModels: vehicleModelsFilter,
        });
      }),
    );
  }

  @Action(FilterVehicleModelsByMake)
  public filterVehicleModelsByMake(
    { getState, patchState }: StateContext<WarrantyCreatorStateModel>,
    { selectedVehicleMake }: FilterVehicleModelsByMake,
  ) {
    const vehicleModelsFilter = getState().vehicleModels.filter(
      (model) => model.makeId === selectedVehicleMake.id,
    );
    patchState({ vehicleModelsFilter: vehicleModelsFilter });
  }

  @Action(PatchFirstStepFormValues)
  public patchFirstStepFormValues(
    { getState, patchState }: StateContext<WarrantyCreatorStateModel>,
    { firstStepPayload, force }: PatchFirstStepFormValues,
  ) {
    patchState({ firstStepPayload: firstStepPayload });
  }

  @Action(PatchSecondStepFormValues)
  public patchSecondStepFormValues(
    { getState, patchState }: StateContext<WarrantyCreatorStateModel>,
    { secondStepPayload, force }: PatchSecondStepFormValues,
  ) {
    const snapshot = getState().secondStepPayload;
    if (snapshot === null) {
      patchState({
        secondStepPayload: {
          ...secondStepPayload,
          insuranceLimitCode: secondStepPayload.insuranceLimit?.value,
        },
      });
      return;
    }

    if (!deepEqual(snapshot, secondStepPayload)) {
      patchState({
        secondStepPayload: {
          ...secondStepPayload,
          insuranceLimitCode: secondStepPayload.insuranceLimit?.value,
        },
      });
    }
  }

  @Action(PatchThirdStepFormValues)
  public patchThirdStepFormValues(
    { patchState }: StateContext<WarrantyCreatorStateModel>,
    { thirdStepPayload }: PatchThirdStepFormValues,
  ) {
    patchState({ thirdStepPayload });
  }

  @Action(PatchFourthStepFormValues)
  public patchFourthStepFormValues(
    { patchState }: StateContext<WarrantyCreatorStateModel>,
    { fourthStepPayload }: PatchFourthStepFormValues,
  ) {
    const nulls = checkForNullValues(fourthStepPayload);

    if (nulls.nullables) {
      console.warn('nullables found in thirdStepPayload: ', nulls.nullKeys);
    }

    if (!nulls.nullables) {
      const premiumSuggested = this.store.selectSnapshot(
        WarrantyOfferState.warrantyOfferSelected,
      )?.calculation.premiumSuggested;

      patchState({
        fourthStepPayload: {
          ...fourthStepPayload,
          premiumSuggested: premiumSuggested ? premiumSuggested : null,
        },
      });
    }
  }

  @Action(PatchFifthStepFormValues)
  public patchFifthStepFormValues(
    { getState, patchState }: StateContext<WarrantyCreatorStateModel>,
    { paymentForm }: PatchFifthStepFormValues,
  ) {
    const secondStepPayload = getState().secondStepPayload;

    patchState({
      secondStepPayload: {
        ...secondStepPayload,
        paymentForm: paymentForm,
      },
    });
  }

  @Action(SetActiveStep)
  public setActiveStep(
    { patchState }: StateContext<WarrantyCreatorStateModel>,
    { step }: SetActiveStep,
  ) {
    patchState({
      activeStep: step,
    });
  }

  @Action(ResetPolicyCreatorState)
  public resetState({ setState }: StateContext<WarrantyCreatorStateModel>) {
    return setState(defaults);
  }

  @Action(SetStepsValidators)
  public setStepsValidators(
    { getState, patchState }: StateContext<WarrantyCreatorStateModel>,
    { stepValidator, forceClean }: SetStepsValidators,
  ) {
    if (forceClean) {
      return patchState({
        stepsValidator: {
          ...stepValidator,
        },
      });
    }

    const stepsValidatorDto = getState().stepsValidator;
    return patchState({
      stepsValidator: {
        ...stepsValidatorDto,
        ...stepValidator,
      },
    });
  }

  @Action(EngineTypeOptions)
  public engineTypeOptions(
    { patchState }: StateContext<WarrantyCreatorStateModel>,
    { vehicleTypeCode }: EngineTypeOptions,
  ) {
    return this.warrantyCreatorService.getEngineTypeOptions().pipe(
      tap((engineTypes) => {
        if (vehicleTypeCode && vehicleTypeCode === 'BK') {
          patchState({
            engineTypeOptions: engineTypes
              .filter((type) => type.code !== 'ELECTRIC')
              .sort((a, b) => a.name.localeCompare(b.name)),
          });
          return;
        }

        patchState({
          engineTypeOptions: engineTypes.sort((a, b) =>
            a.name.localeCompare(b.name),
          ),
        });
      }),
    );
  }
}
