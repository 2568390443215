import { HttpClient, HttpParams } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  RxFormBuilder,
  RxwebValidators,
} from '@rxweb/reactive-form-validators';
import * as moment from 'moment/moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ConfirmSignaturePayload } from '../../../interfaces/idefend/confirm-signature-payload.interface';
import { Currency } from '../../../interfaces/idefend/currency.interface';
import { QuestionReplyHead } from '../../../interfaces/idefend/interfaces';
import { LocalizationSetting } from '../../../interfaces/idefend/localization-settings.interface';
import { PaginationParams } from '../../../interfaces/idefend/pagination-param.interface';
import { PolicyGroupPayload } from '../../../interfaces/idefend/policy-groups-payload.interface';
import { PolicyGroupResponse } from '../../../interfaces/idefend/policy-groups-response.interface';
import { PortfolioGroups } from '../../../interfaces/idefend/portfolio-group.interface';
import { Portfolio } from '../../../interfaces/idefend/portfolio.interface';
import { QuestionManagementReplyPayload } from '../../../interfaces/idefend/question-reply-payload.interface';
import { ReplyResponse } from '../../../interfaces/idefend/question-reply-response.interface';
import { SodanReplayHeads } from '../../../interfaces/idefend/sodan-replay-heads.interface';
import { VehicleMake } from '../../../interfaces/idefend/vehicle-make.interface';
import { VehicleModel } from '../../../interfaces/idefend/vehicle-model.interface';
import { VehicleUsage } from '../../../interfaces/idefend/vehicle-usage.interface';
import { VehiclesCategory } from '../../../interfaces/idefend/vehilces-category.interface';
import { WarrantyCalculateOfferPayload } from '../shared/interfaces/calculate-offer-payload.interface';
import { CalculateOfferResponse } from '../shared/interfaces/calculate-offer-response.interface';
import { CreationLockPayload } from '../shared/interfaces/creation-lock-payload.interface';
import { CreationLockResponse } from '../shared/interfaces/creation-lock-response.interface';
import { EnabledPortfoliosPayload } from '../shared/interfaces/enabled-portfolios-payload.interface';
import { EnabledPortfoliosResponse } from '../shared/interfaces/enabled-portfolios-response.interface';
import { mapOfferLockPayload } from './helpers/offer-lock-payload-mapper';
import { SetStepsValidators } from './store/warranty-creator.actions';
import { WarrantyCreatorState } from './store/warranty-creator.state';
import { GetWarrantyPolicy, LockOffer } from './store/warranty-offer.actions';
import { WarrantyOfferState } from './store/warranty-offer.state';

interface PriceInfoPosition {
  viewportHeight: number;
  asideTabClientHeight: number;
  asideTabOffsetTop: number;
  priceInfoClientHeight: number;
}

interface PriceInfoBehaviorSubject {
  current: PriceInfoPosition;
  previous: PriceInfoPosition;
}

@Injectable({
  providedIn: 'root',
})
export class WarrantyCreatorService {
  private apiBase = environment.apiBase;
  public characterPattern = /\p{L}/u;
  public digitOrSymbolPattern = /\P{L}/gu;
  public isDialogOpen = false;
  public step1Form: UntypedFormGroup;
  public policySignIntervalId: any;

  private priceInfoPosition$: BehaviorSubject<PriceInfoBehaviorSubject> =
    new BehaviorSubject<PriceInfoBehaviorSubject>({
      current: null,
      previous: null,
    });

  constructor(
    private http: HttpClient,
    private store: Store,
    private router: Router,
    private fb: RxFormBuilder,
  ) {}

  initStep1Form() {
    this.step1Form = this.fb.group({
      vehicleType: [null, [RxwebValidators.required()]],
      selectedVehicleMake: [null, [RxwebValidators.required()]],
      selectedVehicleModel: [null, [RxwebValidators.required()]],
      vehiclePrice: [
        null,
        [
          RxwebValidators.required(),
          RxwebValidators.maxNumber({ value: 1000000 }),
        ],
      ],
      vehiclePriceType: [null, [RxwebValidators.required()]],
      vehiclePolicyPrice: [
        null,
        [
          RxwebValidators.required(),
          RxwebValidators.maxNumber({ value: 1000000 }),
        ],
      ],
      vehiclePolicyPriceType: [null, [RxwebValidators.required()]],
      vehiclePriceAc: [null, [RxwebValidators.required()]],
      selectedInsuranceVariant: ['DIAMOND', [RxwebValidators.required()]],
      /* Warranty fields in view order */
      driveType: [null, [RxwebValidators.required()]],
      mileage: [null, [RxwebValidators.required()]],
      engineType: [null, [RxwebValidators.required()]],
      engineSize: [
        null,
        [
          RxwebValidators.required(),
          RxwebValidators.maxNumber({ value: 9999, messageKey: 'maxValue' }),
        ],
      ],
      vehicleRegDate: [
        null,
        // moment().toDate(),
        [
          RxwebValidators.required(),
          // RxwebValidators.minDate({
          //   conditionalExpression: (x) =>
          //     moment(x.vehicleRegDate).toDate() <=
          //     moment().subtract(6, 'years').toDate(),
          // }),
          // RxwebValidators.maxDate({
          //   conditionalExpression: (x) =>
          //     moment(x.vehicleRegDate).toDate() >= moment().toDate(),
          // }),
        ],
      ],
      vehicleBuyDate: [null, [RxwebValidators.required()]],
      manufacturerWarranty: [
        false,
        [
          RxwebValidators.requiredTrue({
            conditionalExpression: (x) =>
              moment(x.vehicleRegDate).toDate() >
              moment().subtract(2, 'years').toDate(),
          }),
        ],
      ],
      mfrWarrantyPeriod: [
        null,
        // [ FIXME: validation not working correctly
        //   RxwebValidators.required({
        //     conditionalExpression: (x) => x.manufacturerWarranty === true,
        //   }),
        // ],
      ],
      mfrWarrantyMileage: [
        null,
        // [ FIXME: validation not working correctly
        //   RxwebValidators.required({
        //     conditionalExpression: (x) => x.manufacturerWarranty === true,
        //   }),
        // ],
      ],
      // TODO: map field in vehicleSnapshot payload
      mfrWarrantyInceptedOn: [
        moment().format('YYYY-MM-DD'),
        // [ FIXME: validation not working correctly
        //   RxwebValidators.required({
        //     conditionalExpression: (x) => x.manufacturerWarranty === true,
        //   }),
        // ],
      ],
      customMfrWarrantyMileage: [
        null,
        // [ FIXME: validation not working correctly
        //   RxwebValidators.required({
        //     conditionalExpression: (x) =>
        //       this.isOtherWarrantyMileageVisible === true &&
        //       x.mfrWarrantyMileage.code === 'other',
        //   }),
        //   RxwebValidators.maxNumber({
        //     value: 1000000,
        //     messageKey: 'maxValue',
        //   }),
        // ],
      ],
      mfrWarrantyInceptedOnSameAsFirstRegisteredOn: [true],
    });
  }

  getNodes(): Observable<any> {
    return this.http.get<any>(`${this.apiBase}/idefend/nodes`);
  }

  handleCityValidation(event: KeyboardEvent) {
    const pattern =
      /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/gm;
    // @ts-ignore-next-line
    let value = event.target.value;

    while (!pattern.test(value)) {
      value = value.slice(0, -1);
    }

    // @ts-ignore-next-line
    event.target.value = value;
  }

  filterNonAlphaChars(event) {
    if (event.key.length === 1) {
      if (!this.characterPattern.test(event.key)) {
        event.target.value = event.target.value.slice(
          0,
          event.target.value.length - 1,
        );
      }
    }

    if (this.digitOrSymbolPattern.test(event.target.value)) {
      event.target.value = event.target.value.slice(
        0,
        event.target.value.search(this.digitOrSymbolPattern),
      );
    }
  }

  /* Initial APK steps */
  getPortfolios(): Observable<Portfolio[]> {
    return this.http.get<Portfolio[]>(`${this.apiBase}/idefend/portfolios`);
  }

  setEnabledPortfolios(
    payload: EnabledPortfoliosPayload,
  ): Observable<EnabledPortfoliosResponse> {
    return this.http.post<EnabledPortfoliosResponse>(
      `${this.apiBase}/idefend/policies/creation/enabled-portfolios`,
      payload,
    );
  }

  getGroups(): Observable<PortfolioGroups[]> {
    return this.http.get<PortfolioGroups[]>(
      `${this.apiBase}/idefend/product-portfolio/groups`,
    );
  }

  getCurrency(id: number): Observable<Currency> {
    return this.http.get<Currency>(`${this.apiBase}/idefend/currencies/${id}`);
  }

  getLocalizationSettings(id: number): Observable<LocalizationSetting> {
    return this.http.get<LocalizationSetting>(
      `${this.apiBase}/idefend/localization-settings/${id}`,
    );
  }

  getSodanReplyHeads(sodanId: string): Observable<SodanReplayHeads> {
    return this.http.get<SodanReplayHeads>(
      `${this.apiBase}/idefend/sodan-reply-heads/${sodanId}`,
    );
  }

  getProductOffersBySodanId(id: string): Observable<QuestionReplyHead> {
    return this.http.get<QuestionReplyHead>(
      `${this.apiBase}/idefend/question-management/sodan-reply-heads/${id}/product-offer`,
    );
  }

  setPolicyGroups(
    payload: PolicyGroupPayload,
  ): Observable<PolicyGroupResponse> {
    return this.http.post<PolicyGroupResponse>(
      `${this.apiBase}/idefend/policy-groups`,
      payload,
    );
  }

  patchQuestionReply(
    payload: QuestionManagementReplyPayload,
    query: { locale: string },
    id: number,
  ): Observable<ReplyResponse> {
    return this.http.put<ReplyResponse>(
      `${this.apiBase}/idefend/question-management/replies/${id}`,
      payload,
      {
        params: query,
      },
    );
  }

  /* Initial APK steps */

  getVehicleCategories(
    query: PaginationParams,
  ): Observable<VehiclesCategory[]> {
    const queryDto = new HttpParams({
      // @ts-ignore-next-line
      fromObject: query,
    });

    return this.http.get<VehiclesCategory[]>(
      `${this.apiBase}/idefend/vehicles/categories`,
      {
        params: queryDto,
      },
    );
  }

  getVehicleUsages(query: PaginationParams): Observable<VehicleUsage[]> {
    const queryDto = new HttpParams({
      // @ts-ignore-next-line
      fromObject: query,
    });

    return this.http.get<VehicleUsage[]>(
      `${this.apiBase}/idefend/vehicles/usages`,
      {
        params: queryDto,
      },
    );
  }

  getVehicleMakes(code?: string): Observable<VehicleMake[]> {
    const queryDto = new HttpParams({
      // @ts-ignore-next-line
      fromObject: {
        categoryCode: code ?? '',
      },
    });

    return this.http.get<VehicleMake[]>(
      `${this.apiBase}/idefend/vehicles/makes`,
      {
        params: queryDto,
      },
    );
  }

  getVehicleModels(): Observable<VehicleModel[]> {
    return this.http.get<VehicleModel[]>(
      `${this.apiBase}/idefend/vehicles/models`,
    );
  }

  getOffer(
    payload: WarrantyCalculateOfferPayload,
  ): Observable<CalculateOfferResponse> {
    return this.http.post<CalculateOfferResponse>(
      `${this.apiBase}/idefend/policies/creation/calculate-offer`,
      payload,
    );
  }

  lockOffer(payload: CreationLockPayload): Observable<CreationLockResponse> {
    return this.http.post<CreationLockResponse>(
      `${this.apiBase}/idefend/policies/creation/lock`,
      payload,
    );
  }

  /* TODO: check response */
  signOffer(id: number, payload: ConfirmSignaturePayload): Observable<unknown> {
    return this.http.put(
      `${this.apiBase}/idefend/policies/${id}/confirm-signature`,
      payload,
    );
  }

  getPolicyByNumber(policyNo: string) {
    return this.http.get<CreationLockResponse[]>(
      `${this.apiBase}/idefend/policies?policyNo=${policyNo}`,
    );
  }

  getPolicy(id: number): Observable<CreationLockResponse> {
    return this.http.get<CreationLockResponse>(
      `${this.apiBase}/idefend/policies/${id}`,
    );
  }

  getAttachments(policyId: number, documentType: string): Observable<any> {
    return this.http.get(
      `${this.apiBase}/idefend/policies/${policyId}/document-download/${documentType}`,
    );
  }

  handlePolicySigning(): void {
    const warrantyCreatorState = this.store.selectSnapshot(
      WarrantyCreatorState.state,
    );
    const warrantyOfferSelected = this.store.selectSnapshot(
      WarrantyOfferState.warrantyOfferSelected,
    );

    const selectedOfferPayload = mapOfferLockPayload(
      warrantyCreatorState,
      warrantyOfferSelected,
    );

    if (selectedOfferPayload) {
      this.store
        .dispatch(new LockOffer(selectedOfferPayload))
        .pipe(take(1))
        .subscribe(() => {
          const warrantyOfferLocked = this.store.selectSnapshot(
            WarrantyOfferState.warrantyOfferLocked,
          );

          if (!warrantyOfferLocked) {
            console.error('Warranty offer locked is null');
          }

          const paymentMethod = this.store.selectSnapshot(
            WarrantyCreatorState.fifthStepPayload,
          );
          if (paymentMethod.value !== 'PM_BT') {
            window.open(warrantyOfferLocked.paymentInitiationUrl, '_blank');

            this.policySignIntervalId = setInterval(() => {
              this.store.dispatch(
                new GetWarrantyPolicy(warrantyOfferLocked.id),
              );
            }, 5000);
          }

          if (paymentMethod.value === 'PM_BT') {
            this.router.navigateByUrl(`/cta/upload/${warrantyOfferLocked.id}`);
          }
        });
    }
  }

  getDiscountCode(discountCode: string): Observable<any> {
    return this.http.get(`${this.apiBase}/discount/code/${discountCode}`);
  }

  getDiscountFromLink(discountLink: string): Observable<any> {
    return this.http.get(`${this.apiBase}/check-link/${discountLink}`);
  }

  getEngineTypeOptions(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.apiBase}/idefend/vehicles/engine-types`,
    );
  }

  checkFormValidity(form: UntypedFormGroup) {
    const validationErrors = [];
    for (const [key, value] of Object.entries(form.controls)) {
      if (value['invalid']) {
        validationErrors.push({
          [key]: value['errors'],
        });
      }
    }

    if (validationErrors.length) {
      console.log('Form is not valid', {
        validationErrors,
      });
    } else {
      console.log('Form is valid');
    }

    return validationErrors;
  }

  scrollToTop() {
    setTimeout(() => {
      if (window.scrollY !== 0) {
        window.scrollTo(0, 0);
      }
    }, 100);
  }

  clearInvalidStepFromValidations(step: string) {
    const stepsValidatorsSnapshot = this.store.selectSnapshot(
      WarrantyCreatorState.getStepsValidators,
    );
    if (stepsValidatorsSnapshot[step] !== undefined) {
      delete stepsValidatorsSnapshot[step];
      this.store.dispatch(
        new SetStepsValidators(stepsValidatorsSnapshot, true),
      );
    }
  }

  // FIXME: not used
  setNewSodanReplyHeads() {
    return this.http.post(
      `${this.apiBase}/warranty-policy-handler/apk-reply`,
      {
        WORRIED_ABOUT_BREAKDOWN: true,
        WORRIED_ABOUT_KEYS: true,
        WORRIED_ABOUT_STK: true,
        WORRIED_ABOUT_OTHER_RISKS: false,
        AWARE_ABOUT_OC: false,
      },
      { responseType: 'text' },
    );
  }

  searchByNip(nip: string) {
    return this.http.post<any>(`${this.apiBase}/gus/searchByNip`, {
      nip: nip,
    });
  }

  private getAlphanumericKeysTable(): number[] {
    const keyTable = [];
    for (let i = 48; i <= 57; i++) {
      keyTable.push(i);
    }

    for (let i = 65; i <= 90; i++) {
      keyTable.push(i);
    }

    for (let i = 97; i <= 122; i++) {
      keyTable.push(i);
    }

    return keyTable;
  }

  handleMaskKeydown(
    event: KeyboardEvent,
    control: AbstractControl,
    additionalKeysAllowed: number[] = [],
  ) {
    const alphanumericKeys = this.getAlphanumericKeysTable();
    let isKeyAllowed = false;

    if (event.keyCode) {
      isKeyAllowed =
        alphanumericKeys.includes(event.keyCode) ||
        additionalKeysAllowed.includes(event.keyCode);
    } else if (event['keyIdentifier']) {
      isKeyAllowed =
        alphanumericKeys.includes(event['keyIdentifier']) ||
        additionalKeysAllowed.includes(event['keyIdentifier']);
    }

    if (!isKeyAllowed) {
      console.log('not allowed key', event.keyCode || event['keyIdentifier']);
    }

    if (control?.value && isKeyAllowed) {
      if (typeof control.value === 'string') {
        event.preventDefault();
        const selectionStart = event.target['selectionStart'];
        const selectionEnd = event.target['selectionEnd'];
        const prefix = control.value?.substring(0, selectionStart) || '';
        const postfix = control.value?.substring(selectionEnd + 1) || '';
        const result = prefix + event.key + postfix;
        control.patchValue(result);
        event.target['selectionStart'] = selectionStart + 1;
        event.target['selectionEnd'] = selectionEnd + 1;

        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });
        control.updateValueAndValidity({
          onlySelf: true,
          emitEvent: true,
        });
      }
    }
  }

  public patchPrimeNgInputValue(
    event: { orignalEvent: InputEvent; value: number; formattedValue: string },
    control?: AbstractControl,
  ) {
    if (!control) {
      const vehiclePriceInput = this.step1Form.get('vehiclePrice');
      const vehiclePolicyPriceInput = this.step1Form.get('vehiclePolicyPrice');
      vehiclePriceInput.patchValue(event.value);
      vehiclePolicyPriceInput.patchValue(event.value);
      return;
    }

    control.patchValue(event.value);
  }

  public setPriceInfoPositionFixed(
    asideTab: ElementRef,
    priceInfoComponent: ElementRef,
  ): boolean {
    if (asideTab && priceInfoComponent) {
      const viewportHeight = window.innerHeight;
      const asideTabClientHeight = asideTab?.nativeElement?.clientHeight;
      const asideTabOffsetTop = asideTab?.nativeElement?.offsetTop;
      const priceInfoClientHeight =
        priceInfoComponent?.nativeElement?.clientHeight;

      this.priceInfoPosition$.next({
        previous: {
          ...this.priceInfoPosition$.getValue().current,
        },
        current: {
          viewportHeight,
          asideTabClientHeight,
          asideTabOffsetTop,
          priceInfoClientHeight,
        },
      });

      const currentValue = this.priceInfoPosition$.getValue().current;
      const previousValue = this.priceInfoPosition$.getValue().previous;

      if (!this.deepEqual(currentValue, previousValue)) {
        if (viewportHeight && asideTabClientHeight && priceInfoClientHeight) {
          return viewportHeight - asideTabOffsetTop > priceInfoClientHeight;
        }
      }
    }

    return false;
  }

  private deepEqual(object1: Object, object2: Object): boolean {
    const keys1: string[] = Object.keys(object1);
    const keys2: string[] = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects: boolean = this.isObject(val1) && this.isObject(val2);
      if (
        (areObjects && !this.deepEqual(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }

    return true;
  }

  private isObject(object: Object): boolean {
    return object != null && typeof object === 'object';
  }
}
